import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";

import "../styles/pages/Platform.scss";

import Hero from "../assets/images/plataforma-section-hero.png";
import SectionThreeImage from "../assets/images/plataforma-2.png";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";

export default function Platform() {
  const intl = useIntl();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "platform-title" })} />
      <div className="platform">
        <section className="platform__section-one">
          <div className="platform__section-one-hero">
            <h1 className="platform__section-one-title">{intl.formatMessage({ id: "platform-title" })}</h1>
            <img src={Hero} alt="" className="platform__section-one-image" />
          </div>
          <p className="platform__section-one-text">
            {intl.formatMessage({ id: "platform-text1" })}
            <br />
            <br />
            {intl.formatMessage({ id: "platform-text2" })}
            <br />
            <br />
            {intl.formatMessage({ id: "platform-text3" })}
          </p>
        </section>
        <section className="platform__section-two">
          <h2 className="platform__section-two-title">{intl.formatMessage({ id: "platform-title-two" })}</h2>
          <ul className="platform__section-two-list">
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item1" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item2" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item3" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item4" })}</li>
          </ul>
          <ul className="platform__section-two-list">
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item5" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item6" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item7" })}</li>
            <li className="platform__section-two-list-item">{intl.formatMessage({ id: "platform-list-item8" })}</li>
          </ul>
        </section>
        <section className="platform__section-three">
          <div className="platform__section-three-side">
            <h2 className="platform__section-three-title">{intl.formatMessage({ id: "platform-title-three" })}</h2>
            <p className="platform__section-three-text">
              <strong>{intl.formatMessage({ id: "platform-text4-strong" })}</strong>
              {intl.formatMessage({ id: "platform-text4" })}
              <br />
              <br />
              <strong>{intl.formatMessage({ id: "platform-text5-strong" })}</strong>
              {intl.formatMessage({ id: "platform-text5" })}
              <br />
              <br />
              <strong>{intl.formatMessage({ id: "platform-text6-strong" })}</strong>
              {intl.formatMessage({ id: "platform-text6" })}
            </p>
          </div>
          <img src={SectionThreeImage} alt="" className="platform__section-three-image" />
        </section>
      </div>
    </Layout>
  );
}
